<template>
  <div class="course-detail pt50 plr40">
    <my-header type="search" v-model="word" @search="onSearch"></my-header>
    <div class="flex row-between ptb30">
      <div class="back flex f20 cup" @click="$router.back()">
        <img src="@/assets/img/back.png" >
        <span>后退</span>
      </div>
      <div class="them f14 c333">{{params.type == 'cases' ? '教案阅读' : '课程示范'}} <span>{{params.type == 'cases' ? 'Teaching plan' : 'Demonstration'}}</span></div>
    </div>
    <div class="lastBase flex">
      <left-list :list="list" :current="current" @click="tap"></left-list>
      <div class="rsinte pl50">
        <div class="text-left" v-html="conent"></div>
      </div>
    </div>
  </div>
</template>

<script>
  import myHeader from '../../components/head.vue';
  import leftList from '../../components/leftList.vue';
  export default{
    components:{
      myHeader,
      leftList
    },
    data(){
      return{
        img10: require("../../assets/img/img10.png"),
        img11: require("../../assets/img/img11.png"),
        word: '',
        params: '',
        list: [],
        current: 0,
        conent: ''
      }
    },
    mounted() {
      this.params = this.$route.query;
      this.getData();
    },
    methods:{
      async getData(){
        var res = await this.$api.get('/api/project_course/courseInfo', this.params);
        if(res.code == 0){
          var data = res.data.list;
          this.list = data;
          this.conent = data[0].info;
        }
      },
      onSearch(){
        if(!this.word) return this.$message.warning('关键词不能为空');
        this.$router.push({name: 'stuff-list', params: {value: this.word}});
      },
      tap(obj){
        this.current = obj.index;
        this.conent = obj.item.info;
      }
    },
    
  }
</script>

<style lang="scss" scoped>
  .course-detail{
    height: 100%;
    .lastBase{
      height: calc(100% - 220px);
      .rsinte{
        overflow: auto;
        height: 100%;
        flex: 1;
        .text-left{
          /deep/ img{
            width: 100%
          }
          // /deep/ video{
          //   width: 100%;
          //   height: 100%;
          // }
        }
        
      }
      
    }
    .back{
      color: #6672fb;
      span{
        font-weight: bold;
      }
      img{
        width: 22px;
        height: 22px;
        vertical-align: middle;
        margin-right: 15px;
      }
    }
    .them{
      font-weight: bold;
      span{
        font-weight: 400;
        font-size: 30px;
        color: #5b68fb;
        padding-left: 20px;
      }
    }
  }
</style>
